import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

const calculateCumulativeTotals = (data) => {

  if (!data || !Array.isArray(data) || data.length === 0) return [];

  data = [...data].reverse();

  return data.reduce((acc, day, index) => {
    const prevDay = index > 0 ? acc[index - 1] : { cumulativeNetPL: 0 };

    acc.push({
      dt: day.dt ?? '',
      cumulativeNetPL: Number((prevDay.cumulativeNetPL + (day.net_pl || 0)).toFixed(2)),
      netPL: Number((day.net_pl || 0).toFixed(2))
    });
    return acc;
  }, []);
};

const CumulativeChart = ({ data }) => {
    const options = {
      chart: {
        type: 'line',
        height: 350,
        stacked: false,
      },
      title: {
        text: 'Cumulative Net P/L and Daily PL',
        align: 'left',
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: [
        {
          title: {
            text: 'Cumulative Net P/L ($)',
          },
          labels: {
            formatter: (value) => `$${value.toFixed(2)}`
          }
        },
        {
          opposite: true,
          title: {
            text: 'Daily PL ($)',
          },
          labels: {
            formatter: (value) => `$${value.toFixed(2)}`
          }
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (value, { seriesIndex }) => `$${value.toFixed(2)}`
        }
      },
      legend: {
        position: 'top',
      },
      
      plotOptions: {
        bar: {
          borderWidth: 0,
          colors: {
            ranges: [{
              from: -Infinity,
              to: 0,
              color: '#FF0000'  // Red for negative values
            }, {
              from: 0,
              to: Infinity,
              color: '#00FF00'  // Green for positive values
            }]
          },
          columnWidth: '50%',
          borderRadius: 1,
          borderWidth: 0,  // Remove border

        },
        stroke: {
            width: [1, 1],  // Line width for line series, 1px for bar series
            colors: ['#008FFB', undefined]  // Color for line series, undefined for bar series
          }
      }
    };

  const series = [
    {
      name: 'Cumulative Net P/L',
      type: 'line',
      data: data.map(day => ({ x: new Date(day.dt).getTime(), y: day.cumulativeNetPL })),
    },
    {
      name: 'Daily PL',
      type: 'column',
      yAxisIndex: 1,
      data: data.map(day => ({ x: new Date(day.dt).getTime(), y: day.netPL })),
    },
  ];

  return (
    <ReactApexChart options={options} series={series} type="line" height={350} />
  );
};

const DailyBreakdownChart = ({ data }) => {
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    title: {
      text: 'Daily Trading Breakdown',
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Amount ($)',
      },
      labels: {
        formatter: (value) => `$${value.toFixed(2)}`,
        style: {
          fontSize: '12px',
        },
      }
    },
    legend: {
      position: 'top',
    },
    tooltip: {
      y: {
        formatter: (value) => `$${value.toFixed(2)}`
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'center',
          orientation: 'vertical',
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: (value) => `$${value.toFixed(2)}`,
      style: {
        fontSize: '10px',
        colors: ['#fff'],
      },
    },
  };

  const series = [
    {
      name: 'Long Profit',
      data: data.map(day => ({ x: new Date(day.dt ?? '').getTime(), y: Number((day.p_long || 0).toFixed(2)) })),
    },
    {
      name: 'Short Profit',
      data: data.map(day => ({ x: new Date(day.dt ?? '').getTime(), y: Number((day.p_short || 0).toFixed(2)) })),
    },
    {
      name: 'Long Loss',
      data: data.map(day => ({ x: new Date(day.dt ?? '').getTime(), y: Number((day.l_long || 0).toFixed(2)) })),
    },
    {
      name: 'Short Loss',
      data: data.map(day => ({ x: new Date(day.dt ?? '').getTime(), y: Number((day.l_short || 0).toFixed(2)) })),
    },
  ];

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

const TradeAnalyticsDashboard = ({ data }) => {
  const cumulativeData = useMemo(() => calculateCumulativeTotals(data), [data]);

  if (!data || !Array.isArray(data) || data.length === 0) {
    return <div>No trading data available.</div>;
  }

  const latestDay = cumulativeData[cumulativeData.length - 1] ?? {};

  return (
    <div className="trade-analytics-dashboard">
      
      <div className="card">
        <h1></h1>
        <CumulativeChart data={cumulativeData} />
        <div className="grid">
          <div>
            <h3>Key Metrics</h3>
            <table>
              <tbody>
                <tr><th>Total Trading Days</th><td>{data.length}</td></tr>
                <tr><th>Cumulative Net P/L</th><td>${latestDay.cumulativeNetPL?.toFixed(2) ?? '0.00'}</td></tr>
              </tbody>
            </table>
            <br/>
          </div>
        </div>
      </div>
      <br/>
      <div className="card">
        <br/>
        <h1></h1>
        <DailyBreakdownChart data={data} />
      </div>
    </div>
  );
};

export default TradeAnalyticsDashboard;