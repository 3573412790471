import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Row, Col } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';

const OrderTracker = ({ orders, tradeBars }) => {
  const [chartData, setChartData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    processData();
  }, [orders, tradeBars]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'America/New_York'
    }).format(date);
  };

  const convertToNYTime = (utcDateString) => {
    const date = new Date(utcDateString);
    const nyDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    return nyDate.getTime();
  };

  const processData = () => {
    const groupedOrders = orders.reduce((acc, orderData) => {
      const createdAt = new Date(orderData.order.created_at).setMilliseconds(0);
      if (!acc[createdAt]) {
        acc[createdAt] = [];
      }
      acc[createdAt].push(orderData);
      return acc;
    }, {});

    const processedChartData = Object.values(groupedOrders).map(bracketGroup => {
      const primaryOrder = bracketGroup.find(orderData => 
        orderData.order.order_class === 'bracket' && orderData.order.status === 'new'
      )?.order;

      if (!primaryOrder) return null;

      const primaryOrderStatus = bracketGroup.find(orderData => 
        orderData.order.id === primaryOrder.id && orderData.order.status === 'canceled'
      ) ? 'canceled' : 'filled';

      if (!primaryOrder) return null;

      const targetOrder = bracketGroup.find(orderData => 
        orderData.order.order_type === 'limit' && 
        orderData.order.side !== primaryOrder.side &&
        orderData.order.status === 'held'
      )?.order;

      const stopLossOrder = bracketGroup.find(orderData => 
        orderData.order.order_type === 'stop' && 
        orderData.order.side !== primaryOrder.side &&
        orderData.order.status === 'held'
      )?.order;

      if (!targetOrder || !stopLossOrder) return null;

      let orderCreationTime = convertToNYTime(primaryOrder.created_at);
      orderCreationTime = orderCreationTime - (60000 * 2)

      if (!tradeBars) return null;

      const relevantTradeBars = tradeBars.filter(bar => {
        const barTime = convertToNYTime(bar.t);
        return barTime >= orderCreationTime;
      });
      
      if (!relevantTradeBars) return null;
      
      let outcome = null;
      const priceData = [];
      const stopLossData = [];
      const takeProfitData = [];
      const predictionData = [];

      relevantTradeBars.forEach((bar, index) => {
        const barTime = convertToNYTime(bar.t);
        const high = parseFloat(bar.high);
        const low = parseFloat(bar.low);
        const targetPrice = parseFloat(targetOrder.limit_price);
        const stopLossPrice = parseFloat(stopLossOrder.stop_price);

        if (!outcome) {
          if (primaryOrder.side === 'buy') {
            if (high >= targetPrice) outcome = { type: 'profit', index };
            else if (low <= stopLossPrice) outcome = { type: 'loss', index };
          } else {
            if (low <= targetPrice) outcome = { type: 'profit', index };
            else if (high >= stopLossPrice) outcome = { type: 'loss', index };
          }
        }

        priceData.push({
          x: barTime,
          y: [parseFloat(bar.open), high, low, parseFloat(bar.close)]
        });

        if (bar.gen_ai_msg) {
          stopLossData.push({
            x: barTime,
            y: parseFloat(bar.gen_ai_msg.stop_loss)
          });

          takeProfitData.push({
            x: barTime,
            y: parseFloat(bar.gen_ai_msg.take_profit)
          });

          predictionData.push({
            x: barTime,
            y: parseFloat(bar.close),
            action: bar.gen_ai_msg.RecommendedAction
          });
        }
      });

      const chartEndIndex = outcome ? outcome.index + 1 : priceData.length;

      return {
        id: primaryOrder.id,
        symbol: primaryOrder.symbol,
        side: primaryOrder.side,
        entryPrice: parseFloat(primaryOrder.limit_price),
        targetPrice: parseFloat(targetOrder.limit_price),
        stopLossPrice: parseFloat(stopLossOrder.stop_price),
        submittedAt: primaryOrder.created_at,
        outcome: outcome ? outcome.type : 'ongoing',
        status: primaryOrderStatus,
        priceData: priceData.slice(0, chartEndIndex),
        stopLossData: stopLossData.slice(0, chartEndIndex),
        takeProfitData: takeProfitData.slice(0, chartEndIndex),
        predictionData: predictionData.slice(0, chartEndIndex)
      };
    }).filter(Boolean);

    setChartData(processedChartData);
  };

  const getChartOptions = (order) => ({
    chart: {
      type: 'candlestick',
      height: 350,
    },
    title: {
      text: `${order.symbol} ${order.side.toUpperCase()} Order (${order.outcome}) - ${order.status}`,
      align: 'left'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        format: 'MM/dd HH:mm'
      }
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    },
    annotations: {
      yaxis: [
        {
          y: order.entryPrice,
          borderColor: '#FEB019',
          label: {
            borderColor: '#FEB019',
            style: { color: '#fff', background: '#FEB019' },
            text: 'Entry'
          }
        },
        {
          y: order.targetPrice,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: { color: '#fff', background: '#00E396' },
            text: 'Target'
          }
        },
        {
          y: order.stopLossPrice,
          borderColor: '#FF4560',
          label: {
            borderColor: '#FF4560',
            style: { color: '#fff', background: '#FF4560' },
            text: 'Stop Loss'
          }
        }
      ],
      xaxis: [
        {
          x: convertToNYTime(order.submittedAt),
          borderColor: '#FEB019',
          label: {
            borderColor: '#FEB019',
            style: { color: '#fff', background: '#FEB019' },
            text: 'Order Placed'
          }
        }
      ]
    },
    tooltip: {
      enabled:false,
      x: {
        format: 'MM/dd HH:mm:ss'
      },
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          if (value === null || value === undefined) {
            return 'N/A';
          }
          return value.toFixed(2);
        }
      }
    }
  });

  const getChartSeries = (order) => [
    { type: 'candlestick', name: 'Price', data: order.priceData },
    { 
      type: 'line', 
      name: 'Stop Loss', 
      data: order.stopLossData.filter(point => point.y !== null && point.y !== undefined)
    },
    { 
      type: 'line', 
      name: 'Take Profit', 
      data: order.takeProfitData.filter(point => point.y !== null && point.y !== undefined)
    },
    {
      type: 'scatter',
      name: 'Predictions',
      data: order.predictionData.filter(point => point.y !== null && point.y !== undefined).map(point => ({
        x: point.x,
        y: point.y,
        marker: {
          size: 5,
          shape: 'circle',
          fillColor: point.action === 'BUY' ? '#00E396' : point.action === 'SELL' ? '#FF4560' : '#FEB019',
        }
      }))
    }
  ];

  return (
    <div className="order-tracker-container">
      <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
        <TabList>
          {chartData.map((order, index) => (
            <Tab key={order.id}>Order {index + 1}</Tab>
          ))}
        </TabList>

        {chartData.map(order => (
          <TabPanel key={order.id}>
                     <Row>
              <Col md={8}>
                <ReactApexChart
                  options={getChartOptions(order)}
                  series={getChartSeries(order)}
                  type="candlestick"
                  height={350}
                />
              </Col>
              <Col md={4}>
                <div className="order-details bg-light p-3 rounded">
                  <h3 className="mb-3">Order Details</h3>
                  <p><strong>Symbol:</strong> {order.symbol}</p>
                  <p><strong>Side:</strong> {order.side}</p>
                  <p><strong>Entry Price:</strong> {order.entryPrice}</p>
                  <p><strong>Target Price:</strong> {order.targetPrice}</p>
                  <p><strong>Stop Loss Price:</strong> {order.stopLossPrice}</p>
                  <p><strong>Submitted At:</strong> {formatDate(order.submittedAt)}</p>
                  <p><strong>Outcome:</strong> {order.outcome}</p>
                  <p><strong>Status:</strong> {order.status}</p>
                </div>
              </Col>
            </Row>
          </TabPanel>
        ))}
      </Tabs>
      <br/>
    </div>
  );
};

export default OrderTracker;